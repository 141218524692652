import React, { useState } from 'react';
import './Modal.scss';

interface ModalProps {
  // isOpen: boolean;
  // onClose: () => void;
  children?:any;
}

// const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children }) => {
  const Modal: React.FC<ModalProps> = ({children }) => {
  // const modalClass = isOpen ? 'modal open' : 'modal';

  return (
    <div className={"modal open"}
    //  onClick={onClose}
     >
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        {children}
      </div>
    </div>
  );
};

export default Modal;
