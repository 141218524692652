import { createSlice } from "@reduxjs/toolkit";

interface sliceState {
  language?: string;
  urlLanguage?: string;
  languageSubmit?: string;
}
const initialState: sliceState = {
  language: undefined,
  urlLanguage: undefined,
  languageSubmit: undefined,
};
const SelectedLangSlice = createSlice({
  name: "selectedLanguage",
  initialState,
  reducers: {
    selectedLanguageFetched: (state, action) => {
      state.language = action.payload;
    },
    urlLanguageFetched: (state, action) => {
      state.language = action.payload;
    },
    submitLanguageFetched: (state, action) => {
      state.languageSubmit = action.payload;
    },
  },
});
export const { selectedLanguageFetched, submitLanguageFetched,urlLanguageFetched } =
  SelectedLangSlice.actions;
export default SelectedLangSlice.reducer;
