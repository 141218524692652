import VideoImage from "../../../assets/images/video_thumbnail_e_samsung.jpg";
import ESamsung from "../../../assets/images/video_thumbnail_e_samsung.jpg";
import EOppo from "../../../assets/images/video_thumbnail_e_oppo.jpg";
import ERedmi from "../../../assets/images/video_thumbnail_e_redmi.jpg";
import EVivo from "../../../assets/images/video_thumbnail_e_vivo.jpg";
import TVivo from "../../../assets/images/video_thumbnail_t_vivo.jpg";
import TOppo from "../../../assets/images/video_thumbnail_t_oppo.jpg";
import TSamsung from "../../../assets/images/video_thumbnail_t_samsung.jpg";
import TRedmi from "../../../assets/images/video_thumbnail_t_redmi.jpg";
import ScreenShot1 from "../../../assets/images/screenshot1.jpg";
import ScreenShot2 from "../../../assets/images/screenshot2.jpg";
import Tvideo6 from "../../../assets/images/comon_tamil.png";
import Tvideo10 from "../../../assets/images/attach_your_jyc_tamil.png";
import Evideo10 from "../../../assets/images/attach_your_jyc_english.png";
import Evideo9 from "../../../assets/images/signup_english.png";
import Tvideo8 from "../../../assets/images/menu_features_tamil.png";
import Evideo8 from "../../../assets/images/menu_features_english.png";
import Evideo1 from "../../../assets/images/one_plus_english.png";
import Tvideo3 from "../../../assets/images/oneplus_tamil.png";
import Tvideo2 from "../../../assets/images/oppo_tamil.png";
import Evideo7 from "../../../assets/images/how_to_take_trips_english.png";
import Evideo5 from "../../../assets/images/oppo_english.png";
import Tvideo4 from "../../../assets/images/redmi_tamil.png";
import Evideo3 from "../../../assets/images/redmi_english.png";
import Tvideo1 from "../../../assets/images/samsung_tamil.png";
import Tvideo9 from "../../../assets/images/signup_tamil.png";
import Evideo6 from "../../../assets/images/comon_english.png";
import Tvideo7 from "../../../assets/images/how_to_take_trips_tamil.png";
import Tvideo5 from "../../../assets/images/vivo_tamil.png";
import Evideo4 from "../../../assets/images/vivo_english.png";
import Evideo2 from "../../../assets/images/video_thumbnail_e_samsung.jpg";
import VivoEnglishStep1 from "../../../assets/Vivo_English/step1.jpg";
import VivoEnglishStep2 from "../../../assets/Vivo_English/step2.jpg";
import VivoEnglishStep3 from "../../../assets/Vivo_English/step3.jpg";
import VivoEnglishStep4 from "../../../assets/Vivo_English/step5.jpg";
import VivoEnglishStep5 from "../../../assets/Vivo_English/step6.jpg";
import VivoEnglishStep6 from "../../../assets/Vivo_English/step7.jpg";
import VivoEnglishStep7 from "../../../assets/Vivo_English/step8.jpg";
import VivoEnglishStep8 from "../../../assets/Vivo_English/step9.jpg";
import VivoEnglishStep9 from "../../../assets/Vivo_English/step10.jpg";
import VivoEnglishStep10 from "../../../assets/Vivo_English/step11.jpg";
import VivoEnglishStep11 from "../../../assets/Vivo_English/step12.jpg";
import VivoEnglishStep12 from "../../../assets/Vivo_English/step14.jpg";
import VivoEnglishHome from "../../../assets/Vivo_English/home_page.jpg";
import VivoTamilStep1 from "../../../assets/Vivo_Tamil/step1.jpg";
import VivoTamilStep2 from "../../../assets/Vivo_Tamil/step2.jpg";
import VivoTamilStep3 from "../../../assets/Vivo_Tamil/step3.jpg";
import VivoTamilStep4 from "../../../assets/Vivo_Tamil/step5.jpg";
import VivoTamilStep5 from "../../../assets/Vivo_Tamil/step6.jpg";
import VivoTamilStep6 from "../../../assets/Vivo_Tamil/step7.jpg";
import VivoTamilStep7 from "../../../assets/Vivo_Tamil/step8.jpg";
import VivoTamilStep8 from "../../../assets/Vivo_Tamil/step9.jpg";
import VivoTamilStep9 from "../../../assets/Vivo_Tamil/step10.jpg";
import VivoTamilStep10 from "../../../assets/Vivo_Tamil/step11.jpg";
import VivoTamilStep11 from "../../../assets/Vivo_Tamil/step12.jpg";
import VivoTamilStep12 from "../../../assets/Vivo_Tamil/step14.jpg";
import VivoTamilHome from "../../../assets/Vivo_Tamil/home_page.jpg";

export const Languages: { [key: string]: { name: string; color: string } } = {
  en: { name: "English", color: "rgba(255, 216, 183, 0.3)" },
  ta: { name: "தமிழ்", color: "rgba(186, 229, 122, 0.3)" },
  // HIN: { name: "हिंदी", color: "rgba(243, 146, 0, 0.3)" },
  // KAN: { name: "ಕನ್ನಡ", color: "rgba(181, 45, 45, 0.3)" },
  // TEL: { name: "తెలుగు", color: "rgba(224, 0, 79, 0.3)" },
  // ASS: { name: "অসমীয়া", color: "rgba(0, 222, 118, 0.3)" },
  // MAR: { name: "मारतो", color: "rgba(18, 70, 137, 0.3)" },
  // ORI: { name: "ଓଡିଆ", color: "rgba(255, 0, 0, 0.5)" },
  // PUN: { name: "ਪੰਜਾਬੀ", color: "rgba(198, 196, 197, 0.3)" },
};
export const PermissionYtUrl: Record<string, string> = {
  samsung: "https://www.youtube.com/shorts/cJde0Gwmd_A",
  oppo: "https://www.youtube.com/shorts/Gur1jBmDgE4",
};

export const thumbnailYtImage: Record<string, string> = {
  Esamsung: ESamsung,
  Eoppo: EOppo,
  Eoneplus: VideoImage,
  Eredmi: ERedmi,
  Evivo: EVivo,
  Tsamsung: TSamsung,
  Toppo: TOppo,
  Toneplus: VideoImage,
  Tredmi: TRedmi,
  Tvivo: TVivo,
  Evideo1: Evideo1,
  Evideo2: Evideo2,
  Evideo3: Evideo3,
  Evideo4: Evideo4,
  Evideo5: Evideo5,
  Evideo6: Evideo6,
  Evideo7: Evideo7,
  Evideo8: Evideo8,
  Evideo9: Evideo9,
  Tvideo1: Tvideo1,
  Tvideo2: Tvideo2,
  Tvideo3: Tvideo3,
  Tvideo4: Tvideo4,
  Tvideo5: Tvideo5,
  Tvideo6: Tvideo6,
  Tvideo7: Tvideo7,
  Tvideo8: Tvideo8,
  Tvideo9: Tvideo9,
  Tvideo10,
  Evideo10
};
export const AccordingImage: Record<string, string> = {
  Eimage1: ScreenShot1,
  Eimage11: ScreenShot1,
  Eimage2: ScreenShot2,
  Eimage3: ScreenShot1,
  Eimage4: ScreenShot2,
  Timage1: ScreenShot1,
  Timage2: ScreenShot2,
  Timage3: ScreenShot1,
  Timage4: ScreenShot2,
  VivoEnglishStep1,
  VivoEnglishStep2,
  VivoEnglishStep3,
  VivoEnglishStep4,
  VivoEnglishStep5,
  VivoEnglishStep6,
  VivoEnglishStep7,
  VivoEnglishStep8,
  VivoEnglishStep9,
  VivoEnglishStep10,
  VivoEnglishStep11,
  VivoEnglishStep12,
  VivoEnglishHome,
  VivoTamilStep1,
  VivoTamilStep2,
  VivoTamilStep3,
  VivoTamilStep4,
  VivoTamilStep5,
  VivoTamilStep6,
  VivoTamilStep7,
  VivoTamilStep8,
  VivoTamilStep9,
  VivoTamilStep10,
  VivoTamilStep11,
  VivoTamilStep12,
  VivoTamilHome,
};