import { configureStore } from '@reduxjs/toolkit'
import SelectedLanguage from './slice/SelectedLanguage';
import ManualUserData from './slice/ManualUserData';
import { combineReducers } from 'redux';
import PermissionSlice from './slice/PermissionSlice';
export const reducers = combineReducers({
    language:SelectedLanguage,
    userData:ManualUserData,
    permision:PermissionSlice
})
export const store = configureStore({
    reducer:reducers
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

