import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { RootState } from '../../redux';
// import ReactPlayer from 'react-player';
// import { Accordion } from 'react-bootstrap';
// import Permission from '../Permission';
import './VideoView.scss'
import { ArrowLeft } from 'react-bootstrap-icons';
// import LoadingSpinner from '../../LoadingSpinner';
import Screenshot1 from '../../../assets/images/screenshot1.jpg'; 
import Screenshot2 from '../../../assets/images/screenshot2.jpg'; 
import { AccordingImage } from '../consonents';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
// import { useCookies } from 'react-cookie';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "../../locales/en/translation.json";
import translationTA from "../../locales/ta/translation.json";
import { PermissionType, UserDataType } from '../../redux/slice/ManualUserData';
const resources = {
  en: {
    translation: translationEN,
  },
  ta: {
    translation: translationTA,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});
function VideoView() {
  const permissionData = useSelector((state: RootState) => state.permision.data);
  const submitLanguage = useSelector((state: RootState) => state.language.languageSubmit);
  // const [cookies, setCookie] = useCookies(['languageType']);
  const  paramValue  = useParams();
  const location = useLocation();
  const { search, pathname } = location;
  const searchParams = new URLSearchParams(location.search);
  const language: string = String(searchParams.get('language'));
  const filterVideoContentById: string = String(searchParams.get('id'));
  console.log("paramValue",paramValue.url?.replaceAll("_"," "))
//  const filterVideoContentById =paramValue.url;
 const videoData:any = i18n.store.data[language].translation ;
//  console.log("cookies",cookies.languageType)
 console.log("submitLanguage",submitLanguage)
 console.log("filterVideoContentById",filterVideoContentById)
//  console.log("videoData",videoData.permission)
 const [filteredPermission, setFilteredPermission] = useState<PermissionType>();
 console.log("permissionData",permissionData)
 console.log("filteredPermission",filteredPermission)

  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  type ImageMap = {
    [key: string]: string;
  };
  const imageMap:ImageMap = {
    "Goaira Page #1": Screenshot1,
    "Goaira Page #2": Screenshot2,
    "Goaira Page #3": Screenshot1,
    "Goaira Page #4": Screenshot2,
  };
  // https://youtube.com/shorts/Gur1jBmDgE4?feature=share
  useEffect(() => {
    const loadingTimeout = setTimeout(() => {
      setIsLoading(false);
    }, 500);

    return () => {
      clearTimeout(loadingTimeout);
    };
  }, []);
  useEffect(() => {
    if (permissionData === undefined && videoData && videoData.permission) {
      const foundItem = videoData.permission.find(
        (item: PermissionType) => item.id === Number(filterVideoContentById)
      );
      
      if (foundItem) {
        setFilteredPermission(foundItem);
      } 
    }
    
  }, [permissionData, videoData]);

  // if (permissionData===undefined && filteredPermission===undefined) {
  //   // if (isLoading) {
  //   return <LoadingSpinner />
  // }

  

  return (
    <>
      <div className='video-icon-container'>
        <ArrowLeft
          size={20}
          color='black'
          className='back-icon'
        onClick={()=>navigate(-1)}
        />
      </div>
      
          <div className='video-view-contaier'>
            <div className='video-list-container'>
              <div className='video-container'>
                <div className='video-image-container'>
                  {/* <ReactPlayer
                    url={permissionData?.link ??filteredPermission?.link}
                    controls
                    // playing
                    // style={{ backgroundColor: 'black'}}
                    className='iframe'
                    // loop
                    width='100%'
                    height='100%'
                  /> */}
                   <iframe 
                  //  allowfullscreen="1" 
                   allow="accelerometer;
                    autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    title="Steps to attach your vehicles with Goaira" 
                    width="100%" height="100%" 
                    src={`${permissionData?.link ??filteredPermission?.link}?autoplay=1&amp;mute=0&amp;controls=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;iv_load_policy=3&amp;modestbranding=1&amp;enablejsapi=1&amp;widgetid=1`} 
                    id="widget2"
                    className='iframe'
                    ></iframe>
                </div>
                <div className='video-title'>
                  <h5>{permissionData?.title??filteredPermission?.title}</h5>
                </div>
              </div>
            </div>
            {/* <Accordion
              // defaultActiveKey="0"
              // flush 
              className='Accordin-container'>
              <Accordion.Item eventKey={"0"}>
                <Accordion.Header>Steps</Accordion.Header>
                {(permissionData??filteredPermission)?.accordinPage.map((item, index) => (
                  <Accordion.Body key={index}>
                    <div className='screenshot-content'>
                      <div className='screenshot-container'>
                        <img src={AccordingImage[item.type]} alt="screenshot1" className='screenshot-image' width={"100%"} height={"100%"} />
                      </div>
                      <div className='screenshot-text'>
                        <p>{item.content}</p>
                      </div>
                    </div>
                  </Accordion.Body>
                ))}
                
              </Accordion.Item>
            </Accordion> */}
          </div>
    </>
  )
}

export default VideoView