import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, useParams, useLocation } from "react-router-dom";
import Main from './components/Page/Main';
import NoPage from './components/Page/NoPage';
import VideoView from './components/Page/VideoView';
import Language from './components/Languages';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/language" element={<Language />} />
        <Route path="/view" element={<VideoView />} />
        <Route path="*" element={<NoPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
