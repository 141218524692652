import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import './languages.scss';
import Modal from '../Modal';
import { Languages } from '../Page/consonents';
import { ArrowLeft, CheckCircleFill } from 'react-bootstrap-icons';
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../redux';
import { selectedLanguageFetched, submitLanguageFetched } from '../redux/slice/SelectedLanguage';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import { useCookies } from 'react-cookie';
interface LanguageProps {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
}


// const Language: React.FC<LanguageProps> =({ isModalOpen, setIsModalOpen })=> {
function Language() {
  const selectedLanguage = useSelector((state: RootState) => state.language.language);
  const urlLanguage = useSelector((state: RootState) => state.language.urlLanguage);
  const submitLanguage = useSelector((state: RootState) => state.language.languageSubmit);
  // console.log("selectedLanguage111", selectedLanguage)
  // console.log("submitLanguage", submitLanguage)
  // const [cookies, setCookie] = useCookies(['langauge']);
  // console.warn("cookies.langauge", cookies.langauge)
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const language: string = String(searchParams.get('language'));
  // console.log("language", language);
  const { i18n } = useTranslation();
  const dispatch = useDispatch()
  // const [isModalOpen, setIsModalOpen] = useState(false);
  // useEffect(() => {
  //   document.body.style.overflow = isModalOpen ? 'hidden' : 'auto';
  //   return () => {
  //     document.body.style.overflow = 'auto';
  //   };
  // }, [isModalOpen]);

  // const openModal = () => {
  //   setIsModalOpen(true);
  // };
  useEffect(() => {
    if (selectedLanguage !== submitLanguage) {
      dispatch(selectedLanguageFetched(submitLanguage));
    }
  }, [submitLanguage]);
  const closeModal = () => {
    // setIsModalOpen(false);
    navigate(-1);
  };

  const handleLanguageClick = (language: any) => {
    dispatch(selectedLanguageFetched(language));

  };

  const handleSubmit = () => {
    if (selectedLanguage !== undefined) {
      // dispatch(submitLanguageFetched(selectedLanguage));
      i18n.changeLanguage(selectedLanguage);
      // setCookie('langauge', selectedLanguage, { path: '/' });
    }
    else{
      i18n.changeLanguage(language);
    }
    // setIsModalOpen(false);
    navigate(`/?language=${selectedLanguage??language}`);
  }


  return (
    <div className='w-100'>
      <div className='button-container'>
        {/* <Button variant='outline-info' size='sm' onClick={openModal}>
          {selectedLanguage ? Languages[selectedLanguage].name : Languages[language]?.name}
        </Button> */}
      </div>
      <div>
        <Modal
        // isOpen={isModalOpen} onClose={closeModal}
        >
          <div className='language-title-container'>
            <div className='back-icon-container'>
              <ArrowLeft
                size={20}
                color='black'
                className='back-icon'
                onClick={closeModal}
              />
            </div>
            <h4>Choose a Language</h4>
            <p>You want to view the app in</p>
          </div>
          <div className='position-relative'>
            <div className='language-grid'>
              {Object.keys(Languages).map((key) => (
                <div
                  key={key}
                  className='language-item'
                  style={{
                    backgroundColor: Languages[key].color,
                    // border:
                    //   selectedLanguage === key ? '2px solid red' : 'none',
                  }
                  }
                  onClick={() => handleLanguageClick(key)}
                >
                  <div className='check-icon-container'>
                    {(language === key && selectedLanguage === undefined) ? (
                      <CheckCircleFill
                        size={22}
                        color='red'
                        className='check-icon'
                      />
                    ) : (selectedLanguage === key) && (
                      <CheckCircleFill
                        size={22}
                        color='red'
                        className='check-icon'
                      />
                    )}
                  </div>
                  <p>{Languages[key].name}</p>
                </div>
              ))}
            </div>
          </div>
          <button className="close-modal"
            onClick={handleSubmit}
          >
            Continue to {selectedLanguage ? Languages[selectedLanguage].name : Languages[language].name}
          </button>
        </Modal>
      </div>
    </div>
  );
}

export default Language;
