import { createSlice } from "@reduxjs/toolkit";
export interface FAQItem {
  title: string;
  body: string;
  list?: Record<string, string>;
  footer?: string;
}
interface AccordingType {
  title: string;
  image: string;
  content: string;
  type: string;
}
export interface PermissionType {
  title: string;
  link: string;
  model?:string;
  id?:number;
  language?:string;
  type:string;
  imageLink: string;
  accordinPage: AccordingType[];
}
export interface deviceBasedContentType {
  title: string;
  id?:number;
  link: string;
  language?:string;
  model:string;
  imageLink: string;
  accordinPage: AccordingType[];
}
export interface UserDataType {
  link: string;
  title: string;
  // accordinPage: AccordingType[];
  faq: FAQItem[];
  faqSection: FAQItem[];
  permission: PermissionType[];
}
interface SliceState {
  data?: UserDataType;
  loading: boolean;
}
const initialState: SliceState = {
  data: undefined,
  loading: false,
};

const ManualUserData = createSlice({
  name: "userData",
  initialState,
  reducers: {
    userDataLoading: (state, action) => {
      state.data = action.payload;
    },
    userDataFetched: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
  },
});
export const { userDataLoading, userDataFetched } = ManualUserData.actions;
export default ManualUserData.reducer;
