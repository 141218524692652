import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
// import AuthenticationDenied from '../AuthenticationDenied';
import Home from '../Home';
type Token = string;
function Main() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    // const token = searchParams.get('token');
    // const { token } = useParams<{ token?: Token }>();
    // function validateToken(token?: Token): boolean {
    //     // return token === 'valid_token';
    //     return token === token;
    // }
    // const isTokenValid = validateToken(token);

    // const { token } = useParams<{ token?: Token }>();
    // const [isTokenValid, setIsTokenValid] = useState<boolean | null>(null);

    // useEffect(() => {
    //     const validateToken = async (token: string) => {
    //         try {
    //             const response = await fetch(`/api/validate-token?token=${token}`);
    //             const data = await response.json();
    //             setIsTokenValid(data.isValid);
    //         } catch (error) {
    //             console.error('Error validating token:', error);
    //             setIsTokenValid(false);
    //         }
    //     };

    //     if (token) {
    //         validateToken(token);
    //     } else {
    //         setIsTokenValid(false);
    //     }
    // }, [token]);
    
    // if (isTokenValid === null) {
    //     return <div>Loading...</div>;
    // }
    return (
        <div>
         <Home /> 
        </div>
    );
}

export default Main;
