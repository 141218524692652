import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Provider } from 'react-redux';
import { store } from './components/redux';
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
// import { CookiesProvider } from 'react-cookie';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <Provider store={store}>
      {/* <CookiesProvider> */}
        <App />
        {/* </CookiesProvider> */}
      </Provider>
    </I18nextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// import React from 'react';
// import YouTube from 'react-youtube';
// import './DetailView.scss';

// function DetailView() {
//     // ... userDetail object ...

//     return (
//         <div className="detail-view">
//             <h5>User Detail</h5>
//             <div className="video-view">
//                 <YouTube videoId="v5OlXJniQBI" opts={{ width: '100%', height: '315' }} />
//             </div>
//             {/* ... Rest of your code ... */}
//         </div>
//     );
// }

// export default DetailView;
