// Home.js
import React, { useState, useEffect, Fragment } from 'react';
// import Language from '../../Languages';
import './Home.scss';
import { Accordion, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux';
import { Languages, thumbnailYtImage } from '../consonents';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
// import VideoImage from '../../../assets/images/VideoImage.jpg';
// import { DataOfAssameseContent, DataOfEnglishContent, DataOfHindiContent, DataOfKannadaContent, DataOfMarathiContent, DataOfOdiaContent, DataOfPunjabiContent, DataOfTamilContent, DataOfTeluguContent } from '../Data';
import { FAQItem, PermissionType, UserDataType, deviceBasedContentType, userDataFetched } from '../../redux/slice/ManualUserData';
// import ReactPlayer from 'react-player';
// import VideoView from '../VideoView';
// import { ArrowLeft } from 'react-bootstrap-icons';
import { permissionDataFetched } from '../../redux/slice/PermissionSlice';
// import LoadingSpinner from '../../LoadingSpinner';
import { useTranslation } from "react-i18next";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "../../locales/en/translation.json";
import translationTA from "../../locales/ta/translation.json";
// import { useCookies } from 'react-cookie';
import thumbnail_Placehoder from '../../../assets/images/thumbnail_placeholder.gif'
const resources = {
  en: {
    translation: translationEN,
  },
  ta: {
    translation: translationTA,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

function Home() {
  const selectedLanguage = useSelector((state: RootState) => state.language.language);
  const submitLanguage = useSelector((state: RootState) => state.language.languageSubmit);
  const userData = useSelector((state: RootState) => state.userData?.data);
  const location = useLocation();
  const { search, pathname } = location;
  const searchParams = new URLSearchParams(location.search);
  const language = String(searchParams.get('language'));
  const model: string = String(searchParams.get('model'));
  const [hidePage, setHidePage] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  // const languageData = language ? i18n.store.data[language].translation : i18n.store.data[language].translation;
  const languageData = searchParams.get('language') !== null ? i18n.store.data[language]?.translation : i18n.store.data["en"]?.translation;
  // console.warn("testIng", language === null ? i18n.store.data["T"]?.translation : i18n.store.data[language]?.translation)
  // console.warn("testIng", language === null ? "1" : '2')
  // console.warn("Testing", language === null ? "1" : "2");
  // console.log("Language Type:", typeof language);
  // console.log("Language Value:", language);

  const [videoImage, setVideoImage] = useState<null | string>(null);

  const openModal = () => {
    // navigate(`language?language=${language}`);
    if (searchParams.get('language') !== null) {
      navigate(`language?language=${language}`);
    } else {
      navigate(`language?language=en`);
    }
  };

  const handleClickToNavigation = (data: any) => {
    dispatch(permissionDataFetched(data))
    // navigate(`/view?id=${data.id}&language=${language}`);
    if (searchParams.get('language') !== null) {
      navigate(`/view?id=${data.id}&language=${language}`);
    } else {
      navigate(`/view?id=${data.id}&language=en`);
    }
  }

  const selectedLanguages = submitLanguage || language;

  const availableLanguageKeys = Object.keys(Languages);
  const filteredLanguageKeys = availableLanguageKeys.filter(key => key === selectedLanguages);

  const output = filteredLanguageKeys.join(', ');
  const [loadCount, setLoadCount] = useState<number>(0);
  const [logMessages, setLogMessages] = useState<number[]>([]);
  // console.log("loadCount",loadCount);
  // console.log("logMessages",logMessages);

  const handleImageLoad = () => {
    if (loadCount < 10) {
      setLoadCount(loadCount + 1);
      setIsLoading(false)
      setLogMessages((prevMessages) => [...prevMessages, loadCount]);
    }
  };
  // console.log("language", language);
  // console.warn("Testing", language === null ? "1" : "2");
  // console.log("Language Type:", typeof language);
  // console.log("Language Value:", language);
  // console.warn("Testing", searchParams.get('language') === null ? "1" : searchParams.get('language'));


  return (
    <>
      <div className="home-container">
        <div className="home-header">
          <div className={!hidePage ? "centered-text" : "centered-text2"}>
            {/* <h5>Support</h5> */}
          </div>
          <Button variant='outline-info' size='sm' onClick={openModal} className='language-button'>
            {/* {language ? Languages[language].name : Languages[language]?.name} */}
            {searchParams.get('language') !== null ? Languages[language]?.name : "English"}
          </Button>
        </div>
        <>
          {/* <div className='video-list-container'>
              {
                typeof languageData === 'object' && languageData !== null &&
                languageData?.deviceBasedContent?.filter((item: any) => item.model === `${output}${model}`)
                  .map((item: deviceBasedContentType, i: number) => {
                    const videoImage = thumbnailYtImage[item.model];
                    return (
                      <div className='video-container' key={i}>
                        <div className='video-image-container'>
                          {videoImage && <img src={videoImage} alt='Video'  onClick={()=>handleClickToNavigation(item)}/>}
                        </div>
                        <div className='video-title'>
                          <h5>{item.title}</h5>
                        </div>
                      </div>
                    );
                  })
              }
            </div> */}
          {/* <div className='video-list-container'>
            {
              typeof languageData === 'object' && languageData !== null &&
              languageData?.permission.map((item: PermissionType, i: number) => {
                const videoImage = thumbnailYtImage[item.type] || '';
                return (
                  <div className='video-container' key={i}>
                    <div className='video-image-container'>
                      {videoImage && (
                        <img src={videoImage} alt='Video-Image' className='video-image' onClick={() => handleClickToNavigation(item)} />
                      )}
                    </div>
                    <div className='video-title'>
                      <h5>{item.title}</h5>
                    </div>
                  </div>
                );
              })
            }
          </div> */}

          <>
            {
              typeof languageData === 'object' && languageData !== null &&
              languageData?.permission.map((item: PermissionType, i: number) => {
                const videoImageUrl = thumbnailYtImage[item.type];
                const logMessageValue = logMessages[i] || -1;
                return (
                  // <div>
                  <Fragment key={item.title}>
                    <img
                      src={videoImageUrl}
                      alt='Video-Image'
                      className={isLoading ? 'loading-thumbnail-hidden' : `${logMessageValue === i ? 'loading-thumbnail' : 'loading-thumbnail-hidden'}`}
                      loading='lazy'
                      onClick={() => handleClickToNavigation(item)}
                      onLoad={handleImageLoad}
                      onLoadedData={() => {
                        setIsLoading(false);
                        setVideoImage(videoImageUrl);
                      }}
                    />
                    <div className={isLoading ? 'loading-video-title-hidden' : "loading-video-title"}>
                      <h5>{item.title}</h5>
                    </div>
                  </Fragment>
                  // </div>

                );
              })
            }
          </>
          {/* <>
            {
              typeof languageData === 'object' && languageData !== null &&
              languageData?.permission.map((item: PermissionType, i: number) => {
                const videoImage = thumbnailYtImage[item.type] || '';
                return (
                  <div className='video-list-container'>
                    <div className='video-container' key={i}>
                      <div className='video-image-container'>
                        {videoImage && (
                          <img src={videoImage} alt='Video-Image' className='video-image' onClick={() => handleClickToNavigation(item)} />
                        )}
                      </div>
                      <div className='video-title'>
                        <h5>{item.title}</h5>
                      </div>
                    </div>
                  </div>
                );
              })
            }
          </> */}
          {/* <div className="faq-container">
              {
                typeof languageData === 'object' && languageData !== null && languageData?.faq.map((faqItem: FAQItem, index: number) => (
                  <div key={index} className="faq-item">
                    <h3>{faqItem.title}</h3>
                    <p>{faqItem.body}</p>
                    {faqItem.list && (
                      <ul>
                        {Object.values(faqItem.list).map((item, index) => (
                          <li key={index}>{item}</li>
                        ))}
                      </ul>
                    )}
                    {faqItem.footer && <p className="footer">{faqItem.footer}</p>}
                  </div>
                ))}
            </div> */}
          {/* <Accordion
              // defaultActiveKey="0"
              className='Accordin-container'>
              {
                typeof languageData === 'object' && languageData !== null && languageData?.faqSection.map((faqItem: FAQItem, index: number) => (
                  <Accordion.Item eventKey={String(index)} key={index}>
                    <Accordion.Header>{faqItem.title}</Accordion.Header>
                    <Accordion.Body>
                      <div className="faq-item faq-item-text" style={{ fontWeight: '500' }}>
                        <p>{faqItem.body}</p>
                        {faqItem.list && (
                          <ul>
                            {Object.values(faqItem.list).map((item, index) => (
                              <li key={index}>{item}</li>
                            ))}
                          </ul>
                        )}
                        {faqItem.footer && <p className="footer">{faqItem.footer}</p>}
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
            </Accordion> */}
        </>
      </div>
    </>
  );
}

export default Home;
